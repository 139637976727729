import React from 'react'

import icons from '../../landingsAssets/icons.svg'
import image02 from '../../landingsAssets/TenantPrescreeningImages/image02.png'
import image03 from '../../landingsAssets/TenantPrescreeningImages/image03.jpg'
import image07 from '../../landingsAssets/TenantPrescreeningImages/image07.jpg'
import LandingHeader from '../../components/header/LandingHeader'
import LinkWithGtag from '../../components/landings/LinkWithGtag'

const TenantPrescreening = () => (
  <div id='wrapperLanding-tenantPrescreening'>
    <LandingHeader
      title='FaceFile: One way video interviews to screen candidates faster'
      stylesHref='/styles/tenantPrescreening.css'
    />
    <div id='main'>
      <div className='inner'>
        <div id='container02' className='container columns full screen'>
          <div className='wrapperLanding-tenantPrescreening'>
            <div className='inner'>
              <div>
                <div id='image02' className='image' data-position='center'>
                  <LinkWithGtag href='https://facefile.co/' className='frame'>
                    <img src={image02} alt='' />
                  </LinkWithGtag>
                </div>
                <h2 id='text14'>Rental Lead Qualification Template</h2>
                <p id='text04'>On-way video interview template</p>
                <p id='text11'>Use this one-way video interview template to qualify tenant leads 4X faster. Instead of repetitive phone calls and ineffective emails, send this video form to all potential tenants at the same time.</p>
              </div>
              <div>
                <p id='text12'>Sign up to use this template for free</p>
                <p id='text03'>Start using this template with our Free Plan and <strong>20 FREE responses</strong> per month.</p>
                <ul id='buttons04' className='buttons'>
                  <li>
                    <LinkWithGtag href='https://app.facefile.co/auth/signup' className='button n01'>Sign Up</LinkWithGtag>
                  </li>
                </ul>
                <p id='text07'><em>No credit card required.</em></p>
              </div>
            </div>
          </div>
        </div>
        <div id='container03' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' className='container default'>
          <div className='wrapperLanding-tenantPrescreening'>
            <div className='inner' style={{ paddingRight: '9rem', paddingLeft: '9rem' }}>
              <h2 id='text08'>Ready-to-Use Video Form Template</h2>
              <p id='text09'>Get to know your potential tenants. This video form allows you to collect the necessary data about potential tenants and make an informed decision. Choose this form, customize, and send it to your tenants in minutes.</p>
              <div id='image07' className='image' data-position='center'>
                <span className='frame'><img src={image07} alt='' /></span>
              </div>
              <ul id='links01' className='links'>
                <li className='n01'>
                  <LinkWithGtag href='https://app.facefile.co/auth/signup'>Try this template for free</LinkWithGtag>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id='container01' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' className='container columns full screen'>
          <div className='wrapperLanding-tenantPrescreening'>
            <div className='inner'>
              <div>
                <h2 id='text06'>Automate rental lead qualification</h2>
                <p id='text15'><span className='p'>Qualify your rental leads faster and with minimal effort.<br />Check how easy it is:</span></p>
                <div id='list01' className='list'><ul><li><p>You don&#039;t have to think of the <strong>right questions to ask</strong></p></li><li><p>Add more <strong>ready-to-use questions</strong> from our library</p></li><li><p>Create new questions without recording videos with the <strong>text-to-video generator</strong></p></li><li><p><strong>Email link</strong> to tenants via email or integrate this template with your Leasing Management Software</p></li><li><p>All responses are <strong>transcribed into text</strong> automatically.</p></li></ul></div>
                <p id='text10'>You can experience FaceFile as a tenant and interviewer with a quick interactive demo.</p>
                <ul id='buttons06' className='buttons'>
                  <li>
                    <LinkWithGtag href='https://app.facefile.co/onboarding' className='button n01'>Try a demo</LinkWithGtag>
                  </li>
                </ul>
              </div>
              <div>
                <div id='image03' className='image' data-position='center'>
                  <span className='frame'>
                    <img src={image03} alt='' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='container10' data-scroll-id='scrollpoint03' data-scroll-behavior='default' data-scroll-offset='0' className='container default full screen'>
          <div className='wrapperLanding-tenantPrescreening'>
            <div className='inner'>
              <h2 id='text32'>How to Use The Tenant Qualification Template</h2>
              <p id='text02'>Creating a one-way video survey for your tenants only takes a few minutes from signing up to sharing it with tenants.</p>
            </div>
          </div>
        </div>
        <div id='container07' className='container columns full screen'>
          <div className='wrapperLanding-tenantPrescreening'>
            <div className='inner'>
              <div>
                <ul id='icons02' className='icons'>
                  <li>
                    <LinkWithGtag className='n01' href='https://domain.ext/path' aria-label='Check'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <use xlinkHref={`${icons}#check-light`} />
                      </svg>
                      <span className='label'>Check</span>
                    </LinkWithGtag>
                  </li>
                </ul>
                <p id='text30'>Select a video form template</p>
                <p id='text26'>Select a pre-made form template with pre-recorded video questions. Or customize with questions of your own.</p>
              </div>
              <div>
                <ul id='icons03' className='icons'>
                  <li>
                    <LinkWithGtag className='n01' href='https://domain.ext/path' aria-label='Link'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <use xlinkHref={`${icons}#link`} />
                      </svg>
                      <span className='label'>Link</span>
                    </LinkWithGtag>
                  </li>
                </ul>
                <p id='text31'>Send a link to tenants</p>
                <p id='text27'>Send the link to your tenants or tenant leads. Tenants can self-record video testimonials and responses at their own pace.</p>
              </div>
              <div>
                <ul id='icons01' className='icons'>
                  <li>
                    <LinkWithGtag className='n01' href='https://domain.exhttps://images.ctfassets.net/1d5sc6vl6pww/6OlrMc6hSdcEpQJguTXycl/3900ba2d48eaf8e393865843eb47bab4/icon.pngt/path' aria-label='Star'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                      >
                        <use xlinkHref={`${icons}#star`} />
                      </svg>
                      <span className='label'>Star</span>
                    </LinkWithGtag>
                  </li>
                </ul>
                <p id='text17'>View tenant responses</p>
                <p id='text23'>Sit back, watch video responses, and read response transcripts. Share tenant video responses with your colleagues.</p>
              </div>
            </div>
          </div>
        </div>
        <div id='container11' className='container default full screen'>
          <div className='wrapperLanding-tenantPrescreening'>
            <div className='inner'>
              <h2 id='text01'>Ready to level up your rental leads qualification process?</h2>
              <p id='text28'>Sign up and try FaceFile with our <strong>Free Plan</strong> and <strong>20 FREE responses</strong> per month.</p>
              <ul id='buttons01' className='buttons'>
                <li>
                  <LinkWithGtag href='https://app.facefile.co/auth/signup' className='button n01'>Get started for free</LinkWithGtag>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default TenantPrescreening
